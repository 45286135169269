export default {
  "help": {
    "check_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez consulter notre documentation"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide ?"])}
  },
  "sidebar": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle d'accès"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "other-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paiements"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dSv8rGLEBC5cmw"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie"])},
    "sponsoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPÉRATIONS FINANCIÈRES"])},
    "scolarité": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide ?"])},
    "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
  },
  "button": {
    "See-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "add_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle tranche"])},
    "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le groupe"])},
    "modify_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer (fichier excel)"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la facture"])},
    "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel élève"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier Excel"])},
    "add_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Elèves"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
  },
  "dashboard": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue !"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "share_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager le lien"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])},
    "success_copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien copié dans le presse-papiers !"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour Partager le lien sur Facebook"])},
    "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour Partager le lien sur X"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour Partager le lien sur LinkedIn"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour copier le lien"])},
    "title-card-payemnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus en attente de vérification"])},
    "title-no-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun reçu en attente"])},
    "text-no-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les reçus envoyés par les parents s'afficheront ici"])},
    "total-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "parents_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de parents inscrits sur Eska"])},
    "stats": {
      "money": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Total"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement Total"])}
      },
      "users": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echéance dépassée"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement à echéance dépassé"])}
      },
      "clients": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" En attente"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement en attente"])}
      },
      "sales": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Manuellement"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements confirmés manuellement"])}
      }
    }
  },
  "classroom": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salles de classe"])},
    "classroom_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Liste de classe"])},
    "code_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en attente"])},
    "code_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echéances dépassées"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder"])},
    "filter_option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de classe"])},
    "filter_option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "filter_option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echéances dépassées"])},
    "add_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une classe"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe supprimée avec succès"])},
    "add_class_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez les salles de clesses et définissez pour chacune d'elles les frais attendus. Les parents d'élèves recevront des alertes sur l'application Eska et pouront payer."])},
    "button_new_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nouvelle classe"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Accéder a la classe pour configurer les paiements et ajouter les eleves."])},
    "alert_list_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune classe créer"])},
    "p_list_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de créer des classes, d'y inscrire des élèves, de générer des paiements pour chaque classe et de permettre  aux parents de recevoir des alertes via l'application mobile"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette classe ?"])},
    "modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression de cette classe entraînera la perte de certaines données associées à cette classe mais cette opération n’est pas rétroactive, les paiements déjà envoyés ou réglés ne seront pas impactés."])}
  },
  "classdetails": {
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "no_group_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe de paiement créé "])},
    "no_group_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez cliquer sur le bouton ci-dessous pour créer des groupes."])},
    "select_groupe_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez appliquer un groupe a la classe afin de pourvoir appliquer ses tranches de paiement aux élèves de la classe."])},
    "no_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun tranche configurée."])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "modal_apply_groupe_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point d'appliquer ce groupe à cette classe."])},
    "modal_success_groupe_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe a été appliqué avec succès à la classe. "])},
    "modal_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant ajouter des élèves à la classe et changer le statut de leurs paiements."])},
    "payment_no_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements approuvés manuellement."])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "receipt_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les reçus envoyés par les parents apparaîtront ici."])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre paiements"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "no_other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun autre paiement créé."])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "modal_noinstallment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'appliqr le group a la classe"])},
    "modal_noinstallment_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas appliquer ce groupe à une classe car il ne contient pas de tranche de paiement."])},
    "PendingPayment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en attente"])}
    },
    "PaymentOverdue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paiements en retard"])}
    },
    "class_alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas ajouter d'élèves"])},
    "class_alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez appliquer un groupe à la classe pour pouvoir ajouter des élèves"])}
  },
  "studentlist": {
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élèves"])},
    "no_student_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun élève ajouté"])},
    "no_student_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez ajouter des élèves à la classe afin de pouvoir leur appliquer les tranches de paiement du groupe appliquées à la classe."])},
    "table_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les élèves pour modifier le statut de leurs paiements."])},
    "table_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
    "table_matricul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "table_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "schooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "no_statut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun statut"])},
    "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépassés"])},
    "table_current_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglé"])},
    "select_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le statut"])},
    "select_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la tranche"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "title_modal_apply_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir appliquer ces frais à la classe ?"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "title_modal_no_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun statut sélectionné."])},
    "title_modal_status_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de Changer le statut de ce paiement ?"])},
    "text_modal_status_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et puisque chez eux, comme dans la tête du monde, les amertumes des maladies dominent plus fortement, contre lesquelles toute la profession de guérison s'engourdit, un remède salutaire a été inventé pour que celui qui porte des choses semblables ne voie pas son ami, et un autre remède assez valable a été ajouté aux précautions, à savoir d'envoyer des serviteurs interroger comment ils se portent."])},
    "text_modal_settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En l'absence de reçu, un message sera envoyé aux différents responsables pour les informer de cette modification."])},
    "title_modal_status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut a été modifié avec succès"])},
    "text_modal_status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton Envoyer ci-dessous pour informer les parents des élèves concernés qu'un paiement est en attente."])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paiements"])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "no_statut_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun statut appliqué"])},
    "pending_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en attente"])},
    "settled_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Paiements réglés"])},
    "overdue_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements à echéance depassées"])},
    "select_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour modifier le statut de cette tranche"])}
  },
  "payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de classes"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialité"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant total"])},
    "total_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" tranches "])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Définir les tranches de paiement pour ce groupe"])},
    "button_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe supprimé avec succès"])},
    "no_payment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisez vos classes"])},
    "no_payment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Chaque classe doit être associée à un groupe de paiement. Veuillez créer les différents groupes.<br/> Pour chacun de ces groupes vous allez ensuite définir les tranches de paiements. "])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce groupe ?"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération n'est pas rétroactive, les paiements déjà effectués ou réglés ne seront pas impactés. Vous devrez redéfinir les frais applicables pour les classes auxquelles le groupe a été appliqué."])}
  },
  "newpayment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau groupe "])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un groupe est un ensemble de classe qui paient le même montant. <br/> Ex: 6ème,2ndeA,4ème. Choisissez un niveau et une specialité, puis sauvegarder. "])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "modal_button_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des tranches"])},
    "title_icon_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des tranches "])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe"])},
    "modal_button_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un groupe"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe créer avec succès"])},
    "modal_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant créer les tranches de paiement associées à ce groupe ou créer un autre groupe."])}
  },
  "other_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paiements"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Période"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Unique"])},
    "period_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualiser la liste des périodes"])},
    "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Récurrent"])},
    "title_icon_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le paiement"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le paiement"])},
    "title_no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun autre paiement"])},
    "modal_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de paiement"])},
    "modal_add_card1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement effectué en une seule fois."])},
    "modal_add_card2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement qui se répète de manière régulière, de façon périodique."])},
    "message_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement supprimé avec succès"])}
  },
  "single_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement unique"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un paiement unique"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement créé avec succès"])},
    "page_modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le paiement"])},
    "message_modify_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement modifié avec succès"])},
    "message_modify_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la modification du paiement"])},
    "modal_modify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir modifier ce paiement ?"])},
    "modal_modify_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération n’est pas rétroactive ; les paiements déjà envoyés ou réglés ne seront pas impactés. Vous devrez redéfinir les frais applicables pour les classes aux quelles le paiement est lié."])}
  },
  "recurring_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement récurrent"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un paiement récurrent"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
    "error_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant est requis"])},
    "error_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mois est requis"])},
    "error_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jour est requis"])},
    "add_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une période"])},
    "delete_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la période"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement créé avec succès"])},
    "period_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette section, vous pouvez définir les différentes périodes de paiement applicables, par exemple mensuelles, trimestrielles ou annuelles. Cela nous permettra de mieux comprendre vos besoins en termes de fréquence de paiement."])}
  },
  "installment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tranches de paiement"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "modal_modify_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la tranche"])},
    "title_payement_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais attribuer les tranches à chaque élève de cette classe"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "amount_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le montant"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration des tranches est terminée, vous pouvez consulter vos paiements"])},
    "no_installment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune tranche de paiement "])},
    "no_installment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les tranches de paiement correspondent à des periodes définies pour le versement des frais de scolarité"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "title_icon_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la tranche"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la tranche"])},
    "modal_modify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir modifier cette tranche ?"])},
    "modal_modify_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération n’est pas rétroactive, les paiements déjà envoyés ou réglés ne seront pas impactés. Vous devrez redéfinir les frais applicables pour les classes auxquelles le groupe a été lié."])},
    "modify_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la tranche"])},
    "add_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter  tranche"])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette tranche ?"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération n’est pas rétroactive, les paiements déjà effectués ou réglés ne seront pas impactés. Vous devrez redéfinir les frais applicables pour les classes auxquelles le groupe a été appliqué."])},
    "message_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche supprimée avec succès"])},
    "message_modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche modifiée avec succès"])}
  },
  "params": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez ces formulaires impressionnants pour vous connecter ou créer un nouveau compte dans votre projet gratuitement."])},
    "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de configuration"])},
    "training_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type  formation "])},
    "education_system ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système educatif"])},
    "education_grade ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau educatif"])},
    "country_code ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code pays"])},
    "currency ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dévise"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
    "devise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dévise"])},
    "neighbourhood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quatier"])},
    "school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'établissement"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations incorrects ?"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez contacter la plateforme "])}
  },
  "accounts": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateurs"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
    "title_icon_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les informations"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féminin"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
    "autorisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation"])},
    "no_admint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun administrateur ajouté"])},
    "lock_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir retirer l’accès cet utilisateur?"])},
    "lock_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’utilisateur n’aura plus accès à cette interface d’administration."])},
    "unlock_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûre de vouloir autoriser l’accès à cet utilisateur?"])},
    "unlock_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet utilisateur pourra à nouveau accéder à cet interface d’administration."])},
    "title_icon_lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquer l'utilisateur "])},
    "title_icon_unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débloquer l'utilisateur"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisé "])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqué"])},
    "no_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page réservée aux administrateurs"])},
    "message_lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur  bloqué avec succès"])},
    "message_unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur  débloqué avec succès"])}
  },
  "newaccounts": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel administrateur"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un administrateur"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone (utilisé sur Eska)"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Téléphone (695623235)"])},
    "no_eska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur n'est pas sur Eska."])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur ajouté avec succès."])}
  },
  "paymentpending": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en attente de confirmation"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "matricul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails paiement en attente"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tranche"])},
    "button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "button_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeté"])},
    "button_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "title_modal_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter le paiement"])},
    "p_modal_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquer le motif du rejet du versement "])},
    "modal_reject_motif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le motif...."])},
    "title_modal_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir confirmer ce payment ?"])},
    "text_modal_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vide, quantum, inquam, fallare, Torquate. oratio me istius philosophi non offendit; nam et complectitur verbis, quod vult, et dicit plane, quod intellegam; et tamen ego a philosopho, si afferat eloquentiam, non asperner, si non habeat, non admodum flagitem. re mihi non aeque"])}
  },
  "updatepayment": {
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité"])},
    "no_speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune Specialité"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir modifier ce groupe ?"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe modifié avec success"])}
  },
  "students_detail": {
    "modal_no_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Impossible de supprimer l'élève"])},
    "modal_no_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas supprimer cet élève car il a déjà effectué des paiements."])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Êtes-vous sûr de vouloir supprimer l' élève"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération est irréversible. Son historique des paiements sera conservée."])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros de téléphone des parents"])},
    "phone_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séparer les numéros par des virgules"])},
    "place_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "sexe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feminin"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglé"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" En attente"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre paiements"])}
  },
  "new_student": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel élève"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouvel élève"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "number_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le matricule ou générez le automatiquement"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "gendered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feminin"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros des parents"])},
    "phone_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après avoir ajouté le numéro veuillez appuyer sur la barre d’espace afin de sauvegarder."])},
    "place_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "image_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez dans le cadre d'image pour ajouter une image"])},
    "placeholder_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le(s) numéro(s)"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élève ajouté avec succès"])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel élève"])},
    "old_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien élève"])}
  },
  "import_student": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des élèves à partir d'un fichier XLSX."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez le modèle de fichier XLSX suivant pour remplir les élèves afin de les ajouter à la plateforme (Vous pouvez les remplir dans un fichier Excel en suivant ce modèle et en le sauvegardant à la fin au format XLSX)."])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier XLSX"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour visualiser "])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
    "generate_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si l’élève n’a pas de matricule, Appuyez sur Générer pour créer un nouveau."])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'élèves"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nombre d'élèves à générer"])},
    "old_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anciens élèves"])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux élèves"])},
    "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le nombre de matricules à générer."])},
    "title_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer le matricule"])},
    "title_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour télécharger"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour télécharger le fichier"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer les matricules ?"])},
    "text_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un fichier modèle sera pré-rempli avec des matricules générés."])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer et télécharger"])},
    "btn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non merci !"])},
    "text_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton suivant pour télécharger le fichier"])},
    "top_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez remplir un fichier en respectant le model suivant :"])},
    "btn_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le modèle"])}
  },
  "new_class": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle classe"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une salle de classe"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de la classe"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d’élèves"])},
    "no_speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune Specialité"])}
  },
  "update_class": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la  classe"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une sale de classe"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de la classe"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'élève"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir modifier cette classe ?"])},
    "text_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Paphius quin etiam et Cornelius senatores, ambo venenorum artibus pravis se polluisse confessi, eodem pronuntiante Maximino sunt"])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe modifiée avec succès"])}
  },
  "modal_pending": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer un statut à cette tranche pour les élèves sélectionnés ?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut de paiement de la tranche indiquée, pour tous les élèves sélectionnés, sera défini par l’un des choix ci-dessous. Veuillez cocher le choix adapté, puis confirmer."])},
    "status_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement en attente"])},
    "status_pending_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette tranche sera marquée comme impayée pour les élèves sélectionnés, les demandes de paiements seront envoyées aux parents sur l’appli Eska et ceux-ci recevront une notification."])},
    "status_settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement réglé"])},
    "status_settled_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette option indique que vous avez déjà reçu l’argent des élèves sélectionnés pour cette tranche, le paiement apparaîtra comme réglé sur votre interface, ainsi que sur l’application Eska. Vérifiez soigneusement avant de confirmer."])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une option avant de confirmer. "])}
  },
  "modal_pending_success": {
    "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification envoyée sur Eska"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut modifié avec succès"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cliquez sur le bouton Envoyer ci-dessous pour informer les parents des élèves concernés qu’un paiement est en attente."])}
  },
  "modal_settled_success": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut modifié avec succès"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" En absence de reçu, un message sera envoyé aux différents responsables pour les informer de cette modification."])}
  },
  "receipt_list": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus envoyés"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])}
  },
  "payment_pending": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiemnens en cours"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement en cours."])}
  },
  "no_bill_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements approuvés sans confirmation de facture"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement approuvé."])}
  },
  "duedate_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiemnens à échéance dépassée"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement."])}
  },
  "list_class_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Paiements approuvés regroupés par classe"])}
  },
  "eska": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques générales"])}
  },
  "signin": {
    "login400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect login credentials. Please try again."])},
    "login200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login successful. Welcome!"])},
    "userBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is inactive. Please contact your administrator."])}
  },
  "logout": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir vous déconnecter?"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur déconnecté avec succès."])}
  },
  "message": {
    "notinternet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes hors ligne."])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la facture"])},
    "requiredfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs."])},
    "user404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, la page demandée est introuvable."])}
  },
  "title": {
    "page_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en attentes"])},
    "page_noreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements approuvés sans reçus"])},
    "page_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements à échéance dépassés"])}
  },
  "transaction": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des transactions"])},
    "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout voir"])},
    "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussies"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "id_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id utilisateur"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
    "last_28_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derniers 28 jours"])},
    "since_the_1st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis le premier jour"])},
    "all_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout les statuts"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusées"])},
    "no_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction "])},
    "no_transaction_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pourrez suivre les payments ici en temps réel"])},
    "all_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute les operateurs"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
    "filter_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par date"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les transactions"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])}
  },
  "transfer": {
    "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum de transfert"])},
    "info_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre collecte vous sera virée dès que le montant atteind "])},
    "info_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de transfert: 4770 TTC"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "no_transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun transfert"])},
    "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
    "receiver_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte récepteur"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
    "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de compte"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modifier"])}
  },
  "configuration": {
    "transfert_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La somme minimale de transfert s'élève à"])},
    "bearcosts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporter les frais"])},
    "bearcosts_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les frais de transactions seront prélevés à vous plutôt qu’aux parents."])},
    "splitpayment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement petite tranche"])},
    "splitpayment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les parents pourront payer à leur rythme tant qu’ils restent dans les délais. Vous aurez les détails de chaque paiement, ainsi que le récapitulatif."])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "bank_info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations bancaires"])},
    "admin_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette page est strictement réservée aux administrateurs."])}
  }
}