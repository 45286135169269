<template>
  <!--=========================== Modal ajouter tranche ============================-->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style=" border-bottom:1px solid rgba(255, 255, 255, 0.4);">
            <h6 v-if="configDetails" class="modal-title px-4" id="staticBackdropLabel" style="margin-left: 10px">
              Nouvelle tranche
            </h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="container" style="margin-bottom: 100px">
              <div class="installment">
                <div class="row">
                  <form class="form col-md-12 mt-2" v-for="(installment, index) in installments" :key="index">
                    <div class="row  ">
                      <div class="tranche fw-bold">
                        <h6 class="mx-1 ">
                          {{ $t("installment.installment") }}
                          {{ installment.number }}
                        </h6>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label>
                            {{ $t("installment.amount") }}
                            <em style="color: #f60000">*</em>
                          </label>
                          <argon-input :placeholder="$t('installment.amount_placeholder')" is-required
                            v-model="installment.amount" @input="updateTotalAmount" :class="{
                              required: requiredFields.registration_number,
                              'required-thick':
                                requiredFields.registration_number,
                            }"></argon-input>
                        </div>
                      </div>

                      <div class="col-md-5">
                        <div class="mb-3">
                          <label>
                            {{ $t("installment.due_date") }}
                            <em style="color: #f60000">*</em>
                          </label>
                          <argon-input is-required type="date" v-model="installment.due_date" :class="{
                            required: requiredFields.registration_number,
                            'required-thick':
                              requiredFields.registration_number,
                          }"></argon-input>
                        </div>
                      </div>

                      <div  class="btn col-md-1 bg-danger  border-danger detail" title="Supprimer l'installment"
                        @click="removeInstallment(index)" v-if="index > 0">
                        <i class="i fa fa-times"></i>
                      </div>
                    </div>


                  </form>
                </div>

                <div class="add">
                  <div class="row">
                    <div class="col-md-10"></div>
                    <div class="col-md-2" style="text-align: end">
                      <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="success"
                        :disabled="loading" style="height: 50px; padding-top: 15px" @click="addInstallment"
                        :title="$t('installment.add_installment')">
                        <span><i class="fa fa-plus" style="margin-right: 5px"></i></span>
                      </argon-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style=" border-top:1px solid rgba(255, 255, 255, 0.4);position: relative;">
            <div style="position: absolute; left: 37px; font-size: 15px" class="fw-bold">
              <h6>
                Total :<span class="opacity-6">
                  XAF {{ (totalAmount + totalAmount1).toLocaleString() }}</span>
              </h6>
            </div>
            <argon-button class="btn bg-transparent " data-bs-dismiss="modal"
              style="width: 170px; height: 50px; padding-top: 15px; border:1px solid rgba(250,250,250,0.4);" @click="resetForm">
              {{ $t("button.cancel") }}
            </argon-button>
            <argon-button :loading="loading" :active="buttonActive || showConfirmation" type="button"
              @click="submitInstallments" color="danger" variant="gradient" :disabled="loading" style="
                margin-right: 33px;
                width: 170px;
                height: 50px;
                padding-top: 15px;
              ">
              {{ $t("button.save") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--=========================== Modal modifier  tranche ============================-->
  <div class="modal fade" id="modifier" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="modifier" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style=" border-bottom:1px solid rgba(255, 255, 255, 0.4);">
            <h6 class="modal-title px-4" id="modifier">
              {{ $t("installment.modal_modify_head") }}
            </h6>
            <button @click="annuler" type="button" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="installment">
                <div class="row">
                  <form class="form col-md-12 mb-7" v-if="show_detail_payment">
                    <div class="title mt-3">
                      <h6 class="d-flex">
                        <span class="mt-2"> {{ $t("installment.installment") }} :</span>
                        <argon-input class="mx-3 w-20 px-2"   is-required
                          v-model="selectedInstallment.number"></argon-input>
                      </h6>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label>
                            {{ $t("installment.amount") }}
                            <em style="color: #f60000">*</em>
                          </label>
                          <argon-input :placeholder="$t('installment.amount_placeholder')" is-required
                            v-model="selectedInstallment.amount"></argon-input>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label>
                            {{ $t("installment.due_date") }}
                            <em style="color: #f60000">*</em>
                          </label>
                          <argon-input is-required type="date" v-model="selectedInstallment.due_date"></argon-input>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div class="row text-center mt-3 mb-5" v-if="show_raison_delete">
                    <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-2"></i>
                    <div>
                      <h6>{{ $t("installment.modal_modify_title") }} </h6>
                      <p class="col-md-12 text-justify px-6">
                        {{ $t("installment.modal_modify_text") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
            <argon-button type="button" class="btn bg-transparent " @click="annuler"
              data-bs-dismiss="modal" style="width: 170px; height: 50px; padding-top: 15px; border:1px solid rgba(255, 255, 255, 0.4);">
              {{ $t("button.cancel") }}
            </argon-button>
            <argon-button v-if="show_detail_payment" type="button" @click="modifier" style="
                margin-right: 33px;
                width: 170px;
                height: 50px;
                padding-top: 15px;
              " color="danger" variant="gradient">
              {{ $t("button.modify") }}
            </argon-button>

            <argon-button v-if="show_raison_delete" :loading="loading" :active="buttonActive || showConfirmation"
              type="button" @click="submitModifiedInstallment" style="
                margin-right: 33px;
                width: 170px;
                height: 50px;
                padding-top: 15px;
              " color="danger" variant="gradient">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--=========================== Table des tranches ============================-->

  <div class="container-fluid mb-10" style="border: 1px solid transparent">
    <div class="card px-0 mt-5">
      <div class="card-body px-0">
        <div class="row">
          <div class="col-md-6" v-if="configDetails" style="padding-left: 35px">
            <h6>
              {{ configDetails.grade_code }}
              <span v-if="configDetails.speciality_code === 'Pas de spécialité'">
              </span>
              <span v-if="configDetails.speciality_code">({{ configDetails.speciality_code }})</span>
            </h6>
          </div>
          <div class="col-md-6 text-end" style="padding-right: 30px">
            <argon-button color="success" size="sm" variant="outline" data-bs-toggle="modal"
              data-bs-target="#staticBackdrop" v-if="
                configDetails &&
                configDetails.installment_list &&
                configDetails.installment_list.length > 0
              "><i class="fas fa-plus me-2"></i>
              {{ $t("button.add") }}
            </argon-button>
          </div>
        </div>
        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
          <div>
            <span class="mt-4">
              <Spinner></Spinner>
            </span>
          </div>
        </spinner>

        <table v-else-if="
          configDetails &&
          configDetails.installment_list &&
          configDetails.installment_list.length > 0
        " class="table px-0 mt-6 mb-6">
          <thead>
            <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1);">
              <th scope="col" class="border-0">Label </th>
              <th scope="col" class="text-center border-0">{{ $t("installment.due_date") }}</th>
              <th scope="col" class="text-end border-0">{{ $t("installment.amount") }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(installment, index) in configDetails.installment_list" :key="index"
              style="position: relative; border-bottom:1px solid  rgba(255, 255, 255, 0.1);">
              <td scope="row" class="px-4 border-0" data-bs-toggle="modal" data-bs-target="#modifier"
                @click="openModifyModal(installment.id)" :title="$t('installment.title_icon_edit')">
                {{ $t("installment.installment") }} {{ installment.number }}
              </td>

              <td class="border-0" style="padding-left: 16%">
                <span>{{ installment.due_date }}</span>
              </td>

              <td class="text-end border-0 px-4">
                <span >XAF {{ Number(installment.amount).toLocaleString() }}
                </span>

                <div class="action position-absolute top-0 mx-4 py-2 h-100 px-0" style="right: -20px">
                  <button class="btn btn-link text-secondary mb-0 text-xl" :title="$t('installment.title_icon_edit')"
                    data-bs-toggle="modal" data-bs-target="#modifier" @click="openModifyModal(installment.id)">
                    
                    <i class="bi bi-pencil-fill fs-6 text-success"></i>
                  </button>

                  <button class="btn btn-link text-secondary mb-0 text-xl" :title="$t('installment.title_icon_delete')"
                    data-bs-toggle="modal" data-bs-target="#staticBackdrop2" @click="openDeleteModal(installment.id)">
                    <i class="fa fa-trash-alt fs-6 text-success" aria-hidden="true"></i>
                  </button>
                </div>
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td class="text-end ">
                <h6> Total : <span class="px-3 opacity-6"> XAF {{ (totalAmount + totalAmount1).toLocaleString()
                    }}</span> </h6>
              </td>
            </tr>
          </tbody>

        </table>

        <div class="else text-center pb-5" v-else>
          <div class="container">
            <div class="text mt-3">
              <img class="image" src="@/assets/img/tranches.png" alt="" />
              <h5 class="mt-3">
                {{ $t("installment.no_installment_title") }}
              </h5>

              <p class="mt-3" v-html="$t('installment.no_installment_p')"></p>

              <span class="mt-3">
                <argon-button color="success" size="sm" variant="outline" data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop" class="btn w-20 fs-6 text-center bg-gradient-success"><span
                    class="fa fa-plus-circle m-2"></span>
                  {{ $t("button.add") }}
                </argon-button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--========================= modal delete =================================== -->

  <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel2" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom:1px solid rgba(255, 255, 255, 0.4);">
            <h5 class="modal-title" id="staticBackdropLabel2"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
            <h6>{{ $t("installment.modal_delete_title") }} </h6>
            <p class="text-justify px-6">
              {{ $t("installment.modal_delete_text") }}
            </p>

          </div>
          <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
            <argon-button type="button" class="btn bg-transparent" data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px; border:1px solid rgba(255, 255, 255, 0.4);">
              {{ $t("button.cancel") }}
            </argon-button>

            
              
            <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger"
              variant="gradient" type="button" class="mt-1"
              style="width: 150px; height: 50px; padding-top: 15px; cursor: pointer;"
              @click="deleteInstallment(selectedInstallmentId)">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCreateConfigDetails,
  submitInstallments,
  removeInstallmentById,
  updateInstallment,
  deleteInstallment,
} from "@/utils/services";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../components/Spinner.vue";

export default {
  name: "Config_Detail",
  components: { ArgonInput, ArgonButton, Spinner },
  data() {
    return {
      show_detail_payment: true,
      show_raison_delete: false,
      installments: [],
      installment_list: [],
      configDetails: null,
      loading: false,
      configId: null,
      editMode: false,
      editedIndex: null,
      totalAmount: 0,
      buttonActive: false,
      showConfirmation: false,
      invalidFields: [],
      requiredFields: {
        first_name: false,
        registration_number: false,
      },

      selectedInstallment: {
        id: null,
        number: "",
        amount: "",
        due_date: "",
        label: "",
      },
    };
  },
  computed: {
    numberOfInstallments() {
      return this.configDetails
        ? this.configDetails.installment_list.length
        : 0;
    },
    totalAmount1() {
      if (this.configDetails && this.configDetails.installment_list) {
        // Utiliser reduce pour calculer la somme des montants
        return this.configDetails.installment_list.reduce(
          (total, installment) => {
            return total + parseFloat(installment.amount);
          },
          0
        );
      } else {
        return 0;
      }
    },
  },
  methods: {
    updateTotalAmount() {
      // Calcul de la somme des valeurs des tranches
      this.totalAmount = this.installments.reduce(
        (acc, curr) => acc + parseFloat(curr.amount || 0),
        0
      );
    },
    modifier() {
      this.show_detail_payment = false;
      this.show_raison_delete = true;
    },

    annuler() {
      this.show_detail_payment = true;
      this.show_raison_delete = false;
    },

    openModifyModal(installmentId) {
      const installment = this.configDetails.installment_list.find(
        (item) => item.id === installmentId
      );

      if (installment) {
        this.selectedInstallment = {
          id: installment.id,
          number: installment.number,
          amount: installment.amount,
          due_date: installment.due_date,
          label: installment.label,
        };
      } else {
        console.error("Installment not found with ID:", installmentId);
      }
    },

    // ================= Modifier une tranche ====================

    async submitModifiedInstallment() {
      try {
        if (this.loading) {
          return;
        }

        this.loading = true;
        const installmentId = this.selectedInstallment.id;
        const updatedData = {
          number: this.selectedInstallment.number,
          amount: this.selectedInstallment.amount,
          due_date: this.selectedInstallment.due_date,
          label: this.selectedInstallment.label,
        };

        const res = await updateInstallment(installmentId, updatedData);

        if (res.status === 200) {
          this.showConfirmation = true;
          const message = this.$t("installment.message_modify");
          this.successMessage = toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            this.successMessage = "";
            this.showConfirmation = false;
            window.location.reload();
          }, 3000);
        } else {
          const errorMessage = this.$t("message.installmentUpdateError");
          toast.error(errorMessage, {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour de l'installment :",
          error
        );
      } finally {
        this.loading = false;
      }
    },

    // ================= Ajouter d'autre formulaire a la modal ================

    addInstallment() {
       
      const isValid = this.installments.every((installment) =>
        Object.values(installment).every((value) => value !== "")
      );

       
      if (isValid) {
        this.installments.push({
          number: this.installments.length + this.numberOfInstallments + 1,
          amount: "",
          due_date: "",
          label: "Tranche",
        });

        return;
      }  
    },
    //====================== Supprimer un formulaire de la modal ==================

    removeInstallment(index) {
      // Vérifiez d'abord si l'index est valide
      if (index >= 0 && index < this.installments.length) {
        // Soustrayez le montant retiré de totalAmount
        const removedAmount = parseFloat(this.installments[index].amount || 0);
        this.totalAmount -= removedAmount;

        // Supprimez ensuite l'installment de la liste
        this.installments.splice(index, 1);
      }
    },
    async removeInstallment1(installmentId, index) {
      try {
        await removeInstallmentById(installmentId);
        console.log("Remove Installment Called with ID:", installmentId);
        console.log("Index to Remove:", index);

        this.configDetails.installment_list.splice(index, 1);
      } catch (error) {
        console.error("Erreur lors de la suppression de l'installment:", error);
      }
    },

    //========================== supprimer les installment =============================

    openDeleteModal(installmentId) {
      this.selectedInstallmentId = installmentId;
    },

    async deleteInstallment(installmentId) {
      try {

        if (this.loading) {
          return;
        }
        this.loading = true;

        const deleted = await deleteInstallment(installmentId);

        if (deleted) {
          this.showConfirmation = true;
          const message = this.$t("installment.message_delete");
          toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            window.location.reload();
            this.showConfirmation = false;
          }, 3000);
        }
      } catch (error) {
        console.error("Error while deleting installment:", error);
      } finally {
        this.loading = false;
      }
    },

    //============================== Ajouter des tranche a la config =============================

    async submitInstallments() {
      try {
        if (this.loading) {
          return;
        }

        this.loading = true;

        if (!navigator.onLine) {
          const message = this.$t("message.notinternet");
          toast.error(message, {
            autoClose: 3000,
          });
          return;
        }

        const isValid = this.installments.every((installment) =>
          Object.values(installment).every((value) => value !== "")
        );

        if (!isValid) {
          this.requiredFields.first_name = true;
          this.requiredFields.registration_number = true;
           
          return;
        }

        const allInstallmentsData = {
          payment_config_id: this.configId,
          installment_list: this.installments.map((installment) => ({
            number: installment.number,
            amount: installment.amount,
            due_date: installment.due_date,
            label: installment.label,
          })),
        };

        console.log("Installments data:", allInstallmentsData);

        const res = await submitInstallments(allInstallmentsData);

        if (res.status === 404) {
          const message = this.$t("message.user404");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 201) {
          this.showConfirmation = true;
          const message = this.$t("installment.success_message");
          this.successMessage = toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            this.successMessage = "";
            this.showConfirmation = false;
            window.location.reload();
          }, 3000);
        }

        this.resetForm();
      } catch (error) {
        console.error(
          "Erreur lors de l'enregistrement des installments:",
          error
        );
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      this.installments = [{}];
      this.editMode = false;
      this.editedIndex = null;
      this.number = "";
      this.amount = "";
      this.due_date = "";
      this.label = "";
    },

    //============================== afficher les tranches =========================

    async fetchConfigDetails() {
      this.loading = true;

      try {
        this.configDetails = await getCreateConfigDetails(this.configId);

        if (this.configDetails && this.configDetails.installment_list) {
          console.log(this.configDetails.installment_list);
        }

        if (!this.configDetails) {
          console.warn("Aucun détail de configuration trouvé.");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails de la configuration:",
          error
        );
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.configId = this.$route.params.id;

    this.fetchConfigDetails().then(() => {
      this.installments.push({
        number: this.numberOfInstallments + 1,
        amount: "",
        due_date: "",
        label: "Tranche",
      });
    });
  },
};
</script>

<style>
.else .image {
  width: 300px;
  margin-top: 10px;
}

.info {
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-left: 0;
}

.info h4 {
  margin-bottom: 50px;
}

.card .card-body tr th {
  width: 30%;
}

.form {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.form .detail {
  color: white;
  height: 50px;
  width: 54px;
  padding-top: 15px;
  margin-top:30px;
   
}

.form .detail .i {
  font-size: 18px;
  transform: translateX(-1px);
}

.add {
  margin-top: 20px;
}

.add .button {
  margin-left: auto;
}

.info {
  width: max-content;
  text-align: center;
}

.info .text {
  width: max-content;
  text-align: left;
  margin-left: 2%;
}

.required {
  border: 2px solid #f13939 !important;
  border-radius: 11px;
}

/*=======css modal ====*/
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal .modal-dialog {
  max-width: 55%;
  width: 55;
  margin-left: 30%;
}

.modal .modal-dialog .modal-content {
  background: transparent;
}

.t-titre {
  width: max-content;
  margin-left: 4%;
}

/*=======css table ====*/

.card .table thead th {
  width: 30%;
}


.card .table tbody tr {
  transition: 0.7s;
  cursor: pointer;
}

.table tbody tr td {
  padding-top: 13px;
  padding-bottom: 13px;
}

.action {
  display: none;
}

.card .table tbody tr:hover .action {
  display: block;
}

/*=======css table ====*/

@media (max-width: 767px) {
  .card .card-body .table-responsive {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .modal .modal-dialog {
    max-width: 90%;
    width: 90%;
    margin-left: 5%;
    height: 80%;
  }

  .t-titre {
    width: max-content;
    margin-left: 0%;
  }

  .card .table tbody .else .button-else {
    display: none;
  }
}

@media (min-width: 768px) {
  .card .card-body .table-responsive {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}
</style>
