<template>
  <div class="card mt-4 pb-4" style="height: 240px;">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 px-1 d-flex align-items-center">
          <h6 class="mb-0">{{ $t("classdetails.receipt") }}</h6>
        </div>
        <div class="col-6 text-end px-1" v-if="receipt_table.length > 0">
          <router-link
            :to="{
              name: 'classroom-receipts',
              params: { classId: classroom_id },
            }"
          >
            <argon-button color="success" size="sm" variant="outline"
              >{{ $t("button.See-more") }}
            </argon-button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="card-body p-0 pb-0 mb-0">
      <spinner v-if="loading" class="text-center mt-4 mb-8" style="height: 200px">
        <span class="mt-4"><Spinner></Spinner></span>
      </spinner>

      <table class="table px-0 mt-4 mb-6" v-else-if="receipt_table.length > 0">
        <thead>
          <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1); font-size:0.85rem;">
            <th scope="col" class="px-2 border-0">{{ $t("classdetails.name") }}</th>
            <th scope="col" class="text-center border-0">{{ $t("classdetails.receipt") }}</th>
            <th scope="col" class="text-end px-2 border-0">{{ $t("classdetails.number") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(list, index) in receipt_table.slice(-4)" :key="index" style="border-bottom: 1px solid rgba(255, 255, 255, 0.1);">
            <td class="border-0 " style="font-size:0.85rem;">
              {{ list.student.first_name }} {{ list.student.last_name }} <br>
             
            </td>

            <td class="text-center border-0 ">
              <a  :href="list.payment.receipt_image_url" target="_blank" title="Cliquer pour visualiser le reçu" class="opacity-6" style="font-size:0.85rem;">
                <i class="fas fa-file-pdf" aria-hidden="true"></i> Reçu
              </a>
            </td>

            <td class="text-end position-relative border-0 ">
              <span class="amount1" style="font-size:0.85rem;">
                {{ list.student.registration_number }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-else class="no-receipt">
        <div class="text-recus">
          <p class="w-70 mt-3" style="margin: 0 auto">{{ $t("classdetails.receipt_text") }}</p>
          <img class="image mt-3" src="@/assets/img/reçu.png" alt="Reçu" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3 h-50">
    <div class="card-header">
      <div class="col-6 px-1 d-flex align-items-center">
        <h6 class="mb-0">Suivi Scolaire</h6>
      </div>
    </div>
    <div class="card_body">
      <div class="text-recus">
        <img class="image mt-3" src="@/assets/img/suivi.png" alt="Suivi Scolaire" />
      </div>
    </div>
  </div>
</template>


<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { getclassroomreport } from "@/utils/services";
import Spinner from "../components/Spinner.vue";

export default {
  data() {
    return {
      receipts: [],
      receipt_table: [],
      loading: false,
    };
  },
  name: "receipt-card",
  components: {
    ArgonButton,
    Spinner,
  },

  props: {
    classroom_id: {
      type: Text,
    },
  },
  mounted() {
    this.fetchClassroomReport(this.classroom_id);
  },
  methods: {
    async fetchClassroomReport(classroom_id) {
      try {
        this.loading = true;
        // Appeler la fonction pour récupérer les informations de la classe
        const response = await getclassroomreport(classroom_id);
        if (response) {
          this.classroomReport = response;

          this.receipt_table = this.classroomReport.student_receipt;
        }
      } catch (error) {
        console.error("Error fetching classroom report:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.text-recus {
  text-align: center;
}
.text-recus p {
   font-size:14px;
   width: 40%;
}
.text-recus .image {
  width: 110px;
}
</style>
