<template>




  <div class="py-4 container-fluid">
    
    <div class="row">

      <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
        <div>
          <span class="mt-4">
            <Spinner></Spinner>
          </span>
        </div>
      </spinner>


      <div class="col-lg-12" v-else-if="!hasAdmin">

        <NoAdminUser />
      </div>
      <div class="col-lg-12" v-else>
         
        <projects-table />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectsTable from "@/views/components/ProjectsTable";
import NoAdminUser from "../components/NoAdminUser.vue";
import Spinner from "@/views/components/Spinner.vue";
import { getMembers } from "@/utils/services";
import { ref, onMounted } from "vue";

export default {
  name: "accounts-page",
  data() {
    return {
      isOffline: !navigator.onLine,
      username: "",
    }

  },


  setup() {
    const members = ref([]);
    const loading = ref(false);

    const fetchMembers = async () => {
      try {
        loading.value = true;
        const result = await getMembers();
        if (!navigator.onLine) {
          this.internet = false; // Pas de connexion Internet
          this.no_internet = true; // Afficher le contenu pour pas de connexion Internet
          alert(
            "Vous n'êtes pas connecté à Internet. Veuillez vérifier votre connexion et réessayer."
          );
          return;
        }

        if (result.status === 200) {
          members.value = result.data;
          localStorage.setItem("members", JSON.stringify(result.data));
      
          this.internet = true;
          this.no_internet = false;
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchMembers();
    });



    return {
      members,
      loading


    };


  },


  computed: {
    hasAdmin() {
      return this.members.some(member => member.is_staff);
    }
  },




  components: {
    ProjectsTable,
    NoAdminUser,
    Spinner 
  },

  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  method: {

    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
    },

  },
}
</script>

<style scoped></style>