<template>


  <div class="main-transaction">

     

    <div class="container-fluid">



     

        <div class="modal fade" id="dateFilterModal" tabindex="-1" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="card w-60 mx-auto">
                <div class="modal-header" style="border-bottom:1px solid rgba(255, 255, 255, 0.4);">
                  <h6 class="modal-title" id="exampleModalLabel">{{ $t("transaction.modal_title") }}</h6>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="applyDateFilter">
                  <div class="modal-body">
                    <div class="mb-3">
                      <label for="startDate" class="form-label">{{ $t("transaction.start_date") }}</label>
                      <input type="date" v-model="filterStartDate" class="form-control" id="startDate" required>
                    </div>
                    <div class="mb-3">
                      <label for="endDate" class="form-label">{{ $t("transaction.end_date") }}</label>
                      <input type="date" v-model="filterEndDate" class="form-control" id="endDate" required>
                    </div>
                  </div>
                  <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
                    <button style="border:1px solid rgba(255, 255, 255, 0.4); color:white;" type="button"
                      class="btn bg-transparent" data-bs-dismiss="modal"> {{ $t("button.close") }} </button>
                    <button data-bs-dismiss="modal" type="submit" class="btn btn-success">{{
                      $t("transaction.filter") }}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


      



      <!--==================Alert==================-->

      <div class="card-api mt-4 mb-3 px-3"
        style="border: 1px solid rgba(250, 250, 250, 0.5); border-radius: 5px; background: #111c44;">
        <div class="card-api-content d-flex justify-content-between">
          <div class="d-flex mt-3 pt-1">
            <div class="key pe-2 me-3">
              <i class="bi bi-exclamation-circle-fill fs-1 mt-3 text-danger"></i>
            </div>
            <div class="text mx-3">
             <h6 style="font-size: 14px;"> <span class="info_bank"> {{ $t("transfer.bank") }}  : <img class="ms-2 me-1" :src="this.bank_logo" alt="">  {{ this.bank_slug}}  </span> ,  <span class="ms-3">{{ $t("transfer.account_number") }} :   {{transaction_number}}</span> </h6>
              <p style="font-size: 14px;"> {{ $t("transfer.info_p") }} XAF {{ Number(this.transfer_min_amount).toLocaleString() }}  - <a title="Modifier la limite" style="color:#4f61c4 !important;" target="_blank" href="#">{{ $t("transfer.modify") }} </a></p>
              <p style="font-size: 14px; margin-top: -10px;">
                <a style="color:#4f61c4 !important;" target="_blank" href="#">{{ $t("transfer.info_p2")
                  }} </a>
              </p>
            </div>
          </div>
          <div class="button" style="align-items: center">
            <argon-button data-bs-toggle="modal" data-bs-target="#exampleModal40" color="success" size="xl"
              style="background: #75acff; width: 150px; margin-top: 20px;">
              {{ $t("button.modify") }}
            </argon-button>
          </div>
        </div>
      </div>


      <!--================== Table ==================-->
      <argon-button data-bs-toggle="modal" data-bs-target="#dateFilterModal" color="primary" size="xl"
        style="background: #111c44; width: 200px; height:50px; padding-top: 16px; margin-top: 20px;">
        <i class="icon-filter text-success fa fa-sliders"></i>
        {{ $t("transaction.filter_date") }}
      </argon-button>

      <div class="card px-0 mt-2">
        <div class="card-body px-0">
          <div class="row">
            <div class="col-md-6 px-0"></div>
            <div class="col-md-6 text-end" style="padding-right: 30px">

            </div>
          </div>

          <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
            <div>
              <span class="mt-4">
                <Spinner></Spinner>
              </span>
            </div>
          </spinner>

          <table class="table px-0 mt-6 mb-6" v-else-if="transfers.length > 0">
            <thead>
              <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">

                <th class="border-0">
                  {{ $t("transfer.recipient") }}
                </th>

                <th class=" border-0" style="text-decoration: none">
                  {{ $t("transfer.amount") }}
                </th>

                <th class=" border-0 text-start px-0">
                  {{ $t("transaction.start_date") }}
                </th>

                <th class=" border-0 text-start px-0">
                  {{ $t("transaction.end_date") }}
                </th>

                <th class="text-end border-0">
                  {{ $t("transfer.receiver_account") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="list in transfers" :key="list.id" style="
                             position: relative;
                             border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              ">



                <td class="px-4 border-0 position-relative">
                  {{ list.receiver_name }}

                </td>

                <td class=" border-0 px-4 ">
                  <span>
                    XAF {{ Number(list.amount).toLocaleString() }}</span>
                </td>

                <td class=" ps-0 pe-7 border-0 position-relative">

                  {{ formatDate(list.collected_from) }}

                </td>

                <td class=" ps-0 pe-7 border-0 position-relative">

                  {{ formatDate(list.collected_until) }}


                </td>

                <td class="text-end px-4 border-0 position-relative">
                  {{ list.receiver_account_number }}
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <!--================== NO transaction ==================-->
          <div class="else text-center pb-5" v-else>
            <div class="container">
              <div class="text mt-3">
                <img class="image" src="@/assets/img/transaction.png" alt="" />
                <h5 class="mt-4"> {{ $t("transfer.no_transfer") }} </h5>

                <!---
                                <p class="mt-4 px-4">
                                    Utque proeliorum periti rectores primo catervas densas opponunt et fortes,
                                    deinde leves armaturas, post iaculatores
                                    ultimasque subsidiales acies, si fors adegerit, iuvaturas, ita praepositis urbanae.
                                </p>
                        -->

              </div>
            </div>
          </div>


        </div>
      </div>
    </div>



  </div>




</template>

<script>
import Spinner from "../components/Spinner.vue";
import ArgonButton from "@/components/ArgonButton";
import { getTransfers, getconnection } from "@/utils/services";




function getFormattedDate() {
  const today = new Date();

  // Soustraire 2 jours de la date actuelle
  const twoDaysAgo = new Date(today);
  twoDaysAgo.setDate(today.getDate() - 2);

  const year = twoDaysAgo.getFullYear();
  const month = String(twoDaysAgo.getMonth() + 1).padStart(2, '0');
  const day = String(twoDaysAgo.getDate()).padStart(2, '0');
  const start_date = `${year}-${month}-${day} 06:00:00`;

  const endYear = today.getFullYear();
  const endMonth = String(today.getMonth() + 1).padStart(2, '0');
  const endDay = String(today.getDate()).padStart(2, '0');
  const end_date = `${endYear}-${endMonth}-${endDay} 23:00:00`;

  return { start_date, end_date };
}

/**
function getFormattedDate() {
  const start_date = "2024-08-03 00:00:00";
  const end_date = "2024-09-03 00:00:00";
  return { start_date, end_date };
}
*/


export default {
  name: "historique-Transaction",
  components: { Spinner, ArgonButton },
  data() {
    return {
      loading: false,
      transactionSummary: {
        total: { count: 0, amount: 0 },
        successful: { count: 0, amount: 0 },
        running: { count: 0, amount: 0 },
        failed: { count: 0, amount: 0 },
      },
      schoolId: '',
      xpay_connection_id: '',
      bank_slug:'',
      transaction_number:'',
      transfer_min_amount:'',
      bank_logo:'',
      transfers: [],
      filterStartDate: '',
      filterEndDate: '',

    };
  },





  methods: {
    getFormattedDate,


    // Formater la date
    formatDate(dateString) {
      if (!dateString) return "N/A"; // Handle undefined or null dates
      const [date] = dateString.split('T');
      return date || "Invalid Date"; // Return a fallback for invalid dates
    },

    async fetchgetconnexion() {
      let data = {
        school_id: this.schoolId,
      };

      try {
        this.loading = true;
        let response = await getconnection(data);


        if (response && response.length > 0) {
          const connection = response[0];
          this.xpay_connection_id = connection.id;
          this.bank_slug = connection.bank_slug;
          this.transaction_number= connection.school_account_number;
          this.transfer_min_amount = connection.transfer_min_amount;
          this.bank_logo = connection.bank_logo	


          // Now fetch transfers after setting the xpay_connection_id

          if (this.xpay_connection_id) {
            await this.fetchTransfers();
          }
        } else {
          console.error("Invalid response data or empty array:", response);
        }
      } catch (error) {
        console.error("Error during getconnection call:", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchTransfers() {
      const xpay_connection_id = this.xpay_connection_id;
      const { start_date, end_date } = this.getFormattedDate();
      this.loading = true;

      try {
        let response = await getTransfers(xpay_connection_id, start_date, end_date);
        this.transfers = response || [];
        this.transactionSummary = response.summary || {
          total: { count: 0, amount: 0 },
          successful: { count: 0, amount: 0 },
          running: { count: 0, amount: 0 },
          failed: { count: 0, amount: 0 },
        };
      } catch (error) {
        console.error("Error fetching transfers:", error);
      } finally {
        this.loading = false;
      }
    },

    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
      this.checkInternetConnection();
    },

    // Fonction pour formater la date dans le format "YYYY-MM-DD 00:00:00"
    formatDateToSQL(date) {
      if (!date) return '';
      const selectedDate = new Date(date);
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Ajout du 0 devant le mois si nécessaire
      const day = String(selectedDate.getDate()).padStart(2, '0'); // Ajout du 0 devant le jour si nécessaire
      return `${year}-${month}-${day} 00:00:00`; // Format final
    },

    // Méthode pour appliquer le filtre de date
    async applyDateFilter() {
      if (!this.filterStartDate || !this.filterEndDate) {
        alert("Veuillez sélectionner les deux dates");
        return;
      }

      // Formater les dates pour les envoyer dans le bon format
      const start_date = this.formatDateToSQL(this.filterStartDate);
      const end_date = this.formatDateToSQL(this.filterEndDate);

      // Appel à l'API pour récupérer les transferts filtrés
      try {
        const response = await getTransfers(this.xpay_connection_id, start_date, end_date);
        this.transfers = response || [];

      } catch (error) {
        console.error("Erreur lors de la récupération des transferts :", error);
      }
    },

  },



  mounted() {
    this.schoolId = localStorage.getItem("schoolId");
    this.fetchgetconnexion();
  },
};
</script>

<style>
.info_bank img{
  height:30px;
  width: 30px;
 
  

}
#form {
  background: none;
  border: 1px solid rgba(250, 250, 250, 0.6);
  height: 45px;
}

#dropdown2 .btn {
  height: 45px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  width: 200px;

}

#dropdown2 .dropdown-menu li {
  padding: 7px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.3s;
  color: white;
}

#dropdown2 .dropdown-menu li:hover {
  background: rgba(255, 255, 255, 0.1);
}




#card .card1 {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  color: white;
  border-radius: 10px;
}

#card .card1 span {
  font-size: 18px;
}

#card .card1 .price {
  font-size: 25px;
  font-weight: bold;
}

.table tbody tr td {
  padding-top: 13px;
  padding-bottom: 13px;
}

#span-table {
  width: 150px;
}
</style>